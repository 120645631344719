.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.touchable-team-name{
  color: #ac1750
}
.App-header {
  flex: 1;
  background-color: #ac1750;
  min-height: 15vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.image-style {
  width: 50%;
  height: 5%;
  flex-direction: row;
  margin: 10px;
  padding: 10px;
  border-color: #ac1750;
  border-style: solid;
  border-width: 1px;
  border-radius: 25px; 
  align-items: flex-end;
}

.App-Features {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.headerStyling {
  width: 75%;
  font-size: 20px;
  font-weight: bold;
  margin: 10px;
  text-align: center;
  padding: 10px;
  background-color: #61dafb;
  border-style: solid;
  border-width: 1px;
  border-radius: 25px; 
}

.clubLogo {
  width: 10%;
  height: 10%;
  flex-direction: row;
  margin: 10px;
  padding: 10px;
  border-color: #61dafb;
  border-style: solid;
  border-width: 1px;
  border-radius: 25px; 
  align-items: flex-end;
}

.calendar {
  width: 75%;
  height: 5%;
  flex-direction: row;
  margin: 10px;
  padding: 10px;
  border-color: #61dafb;
  border-style: solid;
  border-width: 1px;
  border-radius: 25px; 
  align-items: flex-end;
}
.fixtures {
  width: 75%;
  height: 5%;
  flex-direction: row;
  margin: 10px;
  padding: 10px;
  border-color: #61dafb;
  border-style: solid;
  border-width: 1px;
  border-radius: 25px; 
  align-items: flex-end;
}
.fixtureDetails {
  width: 75%;
  height: 5%;
  flex-direction: row;
  margin: 10px;
  padding: 10px;
  border-color: #61dafb;
  border-style: solid;
  border-width: 5px;
  border-radius: 25px; 
  align-items: flex-end;
}
.description {
  width: 75%;
  height: 5%;
  flex-direction: row;
  margin: 10px;
  padding: 10px;
  border-color: #ac1750;
  border-style: solid;
  border-width:5px;
  border-radius: 25px; 
  align-items: flex-end;
  word-wrap: break-word;
}

.fixtureButton {
  width: 75%;
  height: 5%;
  flex-direction: row;
  margin: 10px;
  padding: 10px;
  border-color: #61dafb;
  border-style: solid;
  border-width: 1px;
  border-radius: 25px; 
  align-items: flex-end;
}

.input {
  width: 75%;
  height: 5%;
  flex-direction: row;
  margin: 10px;
  padding: 10px;
  border-color: #61dafb;
  border-style: solid;
  border-width: 1px;
  border-radius: 25px; 
  align-items: flex-end;
}

.message-input {
  width: 75%;
  height: 5%;
  flex-direction: row;
  margin: 10px;
  padding: 10px;
  border-color: #61dafb;
  border-style: solid;
  border-width: 1px;
  border-radius: 25px; 
  align-items: flex-end;
}
.calendar {
  background-color: #fefefe;
  border: 1px solid #ac1750;
  padding: 15px;
  border-radius: 5px;
  width: 100%;
  margin: auto;
}
.send-button {
  border-color: #61dafb;
  height: 50px;
  width: 100px;
  border-radius: 25px;
}

.message-container {
  width: 65%;
  height: 5%;
  flex-direction: row;
  margin: 10px;
  padding: 10px;
  border-color: #ff0044;
  background-color:  #fefefe;
  border-style: solid;
  border-width: 1px;
  border-radius: 25px; 
  align-items: flex-end;
}

.club-logo {
  width: 10%;
  height: 10%;
  flex-direction: row;
  margin: 10px;
  padding: 10px;
  border-color: #61dafb;
  border-style: solid;
  border-width: 1px;
  border-radius: 25px; 
  align-items: flex-end;
}

.button {
  width: 75%;
  height: 5%;
  flex-direction: row;
  margin: 10px;
  padding: 10px;
  border-color: #61dafb;
  border-style: solid;
  border-width: 1px;
  border-radius: 25px; 
  align-items: flex-end;
}

.header {
  width: 75%;
  font-size: 20px;
  font-weight: bold;
  margin: 10px;
  text-align: center;
  padding: 10px;
  background-color: #61dafb;
  border-style: solid;
  border-width: 1px;
  border-radius: 25px; 
}
.map-container {
  height: 500px; /* Adjust height as needed */
  width: 100%;
}